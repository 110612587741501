import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"

import Seo from "../../components/seo"

import ProductHero from "../../components/Product/productHero"
import H1 from "../../components/h1"
import ButtonColor from "../../components/buttonColor"
import ProductInfo from "../../components/Product/productInfo"
import ProductPackshot from "../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../components/Product/productPackshotBubbles"
import BubbleBg from "../../components/Product/bubbleBg"
import ProductSidebar from "../../components/Product/productSidebar"
import More from "../../components/more"
import Tabs from "../../components/Tabs/tabs"
import ProductDescription from "../../components/Product/productDescription"
import Energyze from "../../images/media/products/energyze/energyze.svg";
import Ingredients from "../../components/Product/ingredients"
import Ingredient from "../../components/Product/ingredient"
import Effectivness from "../../components/Product/effectivness"
import EffectivnessCard from "../../components/Product/effectivnessCard"
import ActiveIngredients from "../../components/Product/activeIngredients"
import ActiveIngredient from "../../components/Product/activeIngredient"
import DescriptionPackshot from "../../components/Product/descriptionPackshot"
import Container from "../../components/Product/container"





const Product = () => (
    <Layout>
      <Seo title="Nawilżająco-dotleniający sorbet do mycia twarzy - Linia Energyze" />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="lime">
                    <H1>Nawilżająco-dotleniający sorbet do mycia twarzy</H1>
                    <p>Skuteczne oczyszczanie i odżywienie</p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="nawilzajacy-sorbet"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Skutecznie oczyszcza skórę.</li>
                        <li>Intensywnie nawilża i zapobiega przesuszeniu.</li>
                        <li>Dogłębnie odżywia i odświeża skórę.</li>
                        <li>Dotlenia i usprawnia funkcjonowanie komórek.</li>
                    </ul>
                    <Container>
                        <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml-000000000000407511.html" color="lime">Kup w Hebe</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-energyze-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml.html" color="lime">Kup w Ziko Dermo</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-energyze-vitamin-c-nawilzajaco-dotleniajacy-sorbet-do-mycia-twarzy-75-ml-0107786" color="lime">Kup w Gemini</ButtonColor>
                        <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176925-Oxygenetic_Energyze_Vitamin_C_nawilzajaco-dotleniajacy_sorbet_do_mycia_twrazy_75_ml" color="lime">Kup w DOZ</ButtonColor>
                    </Container>
                </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="nawilzajacy-sorbet"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="lime" text="więcej"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar>
                    <Link to="/produkty/rewitalizujace-serum">
                        <StaticImage
                             src="../../images/media/products/energyze/products/rewitalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/produkty/dotleniajacy-sorbet">
                        <StaticImage
                             src="../../images/media/products/energyze/products/nawilzajacy-sorbet.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="nawilzajace-serum"
                        />
                    </Link>
                    <Link to="/produkty/energetyzujace-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/energetyzujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="Energetyzujące serum"
                        />
                    </Link>
                    <Link to="/produkty/normalizujace-serum">
                        <StaticImage
                            src="../../images/media/products/energyze/products/normalizujace-serum.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="normalizujace serum"
                        />
                    </Link>

                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="lime">

                {/* OPIS */}
                <div label="Opis" className="tab-list-active">
                <ProductDescription color="lime">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">
                                <p>
                                    <strong>Sorbet skutecznie oczyszcza skórę twarzy, a dzięki peelingującemu działaniu pyłu z łupin orzechów słodkich migdałów, delikatnie złuszcza martwy naskórek. </strong>
                                </p>
                                <p>
                                    W konsekwencji skóra jest wygładzona i promienna oraz lepiej przyswaja składniki aktywne. Ponadto preparat jest bogaty w cenne witaminy i minerały, dzięki czemu dogłębnie nawilża skórę, chroniąc przed transepidermalną utratą wody (TEWL), odżywia i regeneruje. 
                                </p>
                                <p>
                                    Dodatkowo dotlenia skórę, nadając jej zdrowy koloryt. Serum dzięki swojej lekkiej konsystencji sorbetu jest niezwykle delikatne dla skóry, nie wywołuje uczucia ściągnięcia, pozostawiając ją miękką i gładką w dotyku. Efekt to dogłębnie oczyszczona, wygładzona i długotrwale nawilżona skóra o świeżym wyglądzie.
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/energyze/products/nawilzajacy-sorbet_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="nawilzajacy-sorbet"
                             />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong>Sposób użycia:</strong></p>
                                <p>Stosować rano lub wieczorem na zwilżoną skórę twarzy. Produkt aplikować okrężnymi ruchami, a następnie spłukać wodą. Tylko do użytku zewnętrznego. Unikać bezpośredniego kontaktu z oczami.</p>
                                <br/>
                                <p><strong>Pojemność:</strong></p>
                                <p>75ml</p>
                                <Energyze/>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Baza">
                <Ingredients>
                    <Ingredient name="Witamina C" desc="To silny antyoksydant, który neutralizuje wolne rodniki uszkadzające komórki skóry. Spowalnia procesy starzenia i chroni przed czynnikami zewnętrznymi. Dodatkowo rozjaśnia przebarwienia, a także przyspiesza odnowę komórkową skóry poprzez zwiększenie produkcji kolagenu i elastyny." />
                    <Ingredient name="Kompleks dotleniających minerałów" desc="Składnik, który wiąże cząsteczki tlenu zwiększając ich dostępność w skórze oraz stymuluje metabolizm komórkowy. Dzięki czemu komórki skóry otrzymują niezbędną energię, zaczynają prawidłowo funkcjonować i regenerować się. W efekcie znacznie poprawia się wygląd skóry, staje się ona zdrowa i promienna." />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Składniki aktywne">
                <ActiveIngredients>
                    <ActiveIngredient name="Pył z łupin orzechów słodkich migdałów" desc="Składnik ten wykazuje działanie peelingujące, usuwając martwe komórki naskórka i zanieczyszczenia. Wpływa na odnowę komórkową skóry, pozostawiając ją wygładzoną i rozświetloną." />
                    <ActiveIngredient name="Ekstrakt z ogórka" desc="Cenne źródło witamin i minerałów. Uzupełnia naturalny poziom nawilżenia skóry, zmniejszając dodatkowo transepidermalną utratę wody (TEWL). Wykazuje działanie przeciwutleniające, odświeżające, a także hamujące procesy starzenia." />
                    <ActiveIngredient name="Mleczko oliwkowe" desc="To bogate źródło lipidów i protein, dzięki czemu otula skórę płaszczem ochronnym, intensywnie ją nawilżając. Dodatkowo wykazuje silne działanie antyoksydacyjne, naprawcze oraz przeciwbakteryjne." />
                    <ActiveIngredient name="Olej z awokado" desc="Składnik, który pozytywnie wpływa na stan bariery naskórkowej, zmniejszając transepidermalną utratę wody (TEWL). Ponadto działa przeciwzmarszczkowo, dogłębnie odżywiając, regenerując i wygładzając strukturę skóry." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Skuteczność">

                <Effectivness>
                    <EffectivnessCard color="lime" percentage="93" desc="badanych potwierdza, że przy regularnym stosowaniu skóra odzyskuje zdrowy i promienny wygląd"/>
                    <EffectivnessCard color="lime" percentage="100" desc="badanych potwierdza, że produkt delikatnie złuszcza nie powodując podrażnień"/>
                    <EffectivnessCard color="lime" percentage="90" desc="badanych potwierdza, że produkt zmniejsza widoczność porów"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
